.custom-button {
  .btn {
    border-radius: 10px;
    display: "flex";
    flex-direction: row;
    text-align: center;
    font-family: $font-secondary;
    border: none;
    &:hover {
      filter: brightness(85%);
    }
  }
  .login-btn {
    font-size: 18px;
    background-color: $dark-blue;
    margin-top: 10px;
    min-width: 100%;
    height: 3em;
    &:hover {
      filter: none;
      background-color: $dark-blue-hover;
    }
  }
  .back-btn {
    background-color: #dee2e6;
    color: black;
    padding: 15px;
    min-width: 150px;
    margin: 5px;
    box-shadow: 0px 2.69091px 26.9091px rgba(0, 0, 0, 0.1);
    &.btn-primary:active {
      background: #3131315b;
      color: #ffffff;
    }
    &.small {
      max-width: 80px;
    }
  }
  .test-1 {
    background-color: blue;
    color: #ffffff;
    border: 1px solid black;
    margin: 5px;
  }
  .next-btn {
    background-color: $light-green;
    color: #ffffff;
    padding: 15px;
    min-width: 150px;
    margin: 5px;
    margin-bottom: 32px;
    box-shadow: 0px 3px 27px rgba(0, 0, 0, 0.1);
    &.small {
      max-width: 80px;
    }
    &.x-small {
      min-width: 0;
      max-width: 5em;
      padding: 0.4em 1em;
    }
  }
  .save-btn {
    border: 2px solid $dark-blue;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 14px;
    color: $dark-blue;
    background: transparent;
    padding: 15px 4%;
    width: 88%;
  }
  .delete-btn {
    border: 2px solid $missing-red;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 14px;
    color: $missing-red;
    background: transparent;
    padding: 15px 0;
    text-align: center;
    width: 100%;
    &.btn-primary:active {
      background: $missing-red;
      color: #ffffff;
    }
  }
  .blue-fill-btn {
    box-sizing: border-box;
    border-radius: 10px;
    background: $dark-blue;
    color: #ffffff;
    width: 10rem;
    height: 3rem;

    &.small {
      width: 6rem;
      height: 2.5em;
    }
  }
  .outline-btn {
    box-sizing: border-box;
    border-radius: 10px;
    background: transparent;
    border: 2px solid $dark-blue;
    color: $dark-blue;
    width: 10rem;
    height: 3rem;
  }
}

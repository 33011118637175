.info-tooltip {
  display: inline;
  float: left;
  .btn {
    padding: 1px;
    color: transparent;
    background-color: transparent;
    border: none;
    line-height: 1;
    box-shadow: none;
  }
}
.popover {
  width: 20rem;
  max-width: 50%;
}
.tooltip-container {
  $font-family: $font-secondary;
  padding: 0.7em;
  .tooltip-title {
    color: $dark-blue;
    padding-bottom: 1em;
  }
  .tooltip-content {
    padding-bottom: 1em;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans';
  background-color: #eee;

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }
}
.platform {
  max-width: 700px;
  padding: 50px 20px;
  background-color: white;
  margin: 0 auto;
}

.reporter-claim-card {
  .card {
    width: 20rem;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    margin: 10px 0px;
    cursor: pointer;
    .card-body {
      font-family: $font-secondary;
      font-style: normal;
      text-transform: capitalize;
      width: 96%;
      padding: 5% 0 5% 7%;
      .card-title {
        font-weight: bold;
        font-size: 95%;
        line-height: 25px;
        color: #272727;
        display: inline;
        float: left;
        min-width: 8rem;
        text-align: left;
      }
      .notif {
        padding: 0 0 2% 4%;
        float: left;
      }
      .card-text {
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: $solid-gray;
        display: inline;
        float: left;
        padding-right: 0.5em;
        &.submitted {
          color: $submitted;
          display: inline;
          float: right;
          padding-top: 1%;
        }
        &.completed {
          color: $completed;
          display: inline;
          float: right;
          padding-top: 1%;
        }
        &.approved {
          color: $lcbo-green;
          display: inline;
          float: right;
          padding-top: 1%;
        }
        &.completed_declined {
          color: $completed-declined;
          float: right;
          padding-top: 1%;
          max-width: 7rem;
          text-align: right;
          font-size: 13px;
        }
        &.draft {
          color: $error-red;
          display: inline;
          float: right;
          padding-top: 1%;
        }
        &.incomplete {
          color: $error-red;
          display: inline;
          float: right;
          padding-top: 1%;
        }
        &.date {
          color: $dark-blue;
        }
        &.id {
          padding-top: 1%;
        }
      }
    }
  }
}

.sticky-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 999;
  .footer-children {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 700px;
  }
}

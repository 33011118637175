.claim-page {
  .item-form {
    h4 {
      font-size: 0.9rem;
      font-weight: 600;
      color: #959595;
    }
    h6 {
      font-size: 0.8rem;
      color: $dark-blue;
    }
    p {
      font-size: 0.8rem;
      color: #959595;
    }
  }
  .form-row {
    display: flex;
    flex-direction: row;
    &-tip {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }
    .search-container {
      max-height: 42px;
      .search {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-left: 15%;
        height: 100%;
      }
    }
  }
  .undergo {
    text-align: center;
  }
  .label {
    font-weight: normal;

    color: $solid-gray;
    margin-bottom: 0.2rem;
  }
  .agreement {
    display: flex;
    flex-direction: row;
    .content {
      margin-left: 5px;
      display: flex;
      flex-direction: row;
      .destroy-content {
        display: flex;
        flex-direction: column;
        .do-not-destroy {
          display: flex;
          flex-direction: row;
          justify-content: center;
          text-align: center;
        }
      }

      .asterisk {
        margin-left: 1px;
        color: $error-red;
      }
    }
  }
  .info-fields {
    padding: 1px;
    .info-field {
      padding: 12px 0px;
      .label {
        color: $solid-gray;
        display: flex;
        flex-direction: row;

        font-weight: normal;
        font-size: 11px;
        line-height: 15px;
        margin-bottom: 0.2rem;

        .asterisk {
          margin-left: 1px;
          color: $error-red;
        }
      }
      .lot {
        background-color: #f4f4f4;
        padding: 10px;
        p {
          font-size: 12px;
          margin: 0px;
        }
      }
      .image-copy {
        .info-photo {
          line-height: 23px;
        }
        .asterisk {
          display: inline-block;
          margin-left: 1px;
          color: $error-red;
        }
        p {
          font-size: 12px;
          margin: 0px;
        }
        ul {
          padding-inline-start: 18px;
        }
      }
      &.status-style {
        line-height: 2;
        padding-top: 1rem;
      }
      // colouring claim status text
      // probably a better way to do this lol
      .complete {
        display: inline;
        color: $completed;
      }
      .submitted {
        display: inline;
        color: $submitted;
      }
      .missing {
        display: inline;
        color: $missing-red;
      }
      .incomplete {
        display: inline;
        color: $error-red;

        &.input-warning {
          display: block;
          font-size: 12px;
        }
      }
      .in-progress {
        display: inline;
        color: $completed-declined;
      }
      .mobile-black {
        color: black;
        opacity: 1;
        -webkit-text-fill-color: rgba(0, 0, 0, 1);
        -webkit-opacity: 1;
      }
    }

    .radio-group {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      margin: 20px 0px;
      font-size: 0.9rem;
      .label {
        display: flex;
        flex-direction: row;
        .asterisk {
          color: $error-red;
          margin-left: 1px;
        }
      }
      .radio {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        align-items: center;
        width: 110px;

        .word {
          margin: 0px 12px 0px 5px;
          width: 50px;
          display: inline;
        }
      }
    }
  }
}

.reopen-modal {
  padding: 1rem;
  .modal-header {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
  .modal-body {
    text-align: left;
    line-height: 2;
    .incomplete {
      display: inline;
      color: #eb5757;
    }
  }
  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 3rem;
  }
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: 95%;
  max-width: 900px;
  background-color: white;
  padding: 100px;
  border-radius: 10px;
  h1 {
    color: $lcbo-green;
    font-weight: 700;
  }
  .img-container {
    img {
      height: 82px;
      width: 180px;
    }
  }
  .text-input {
    margin: 5px 0px;
  }
  .reset {
    color: $dark-blue;
    cursor: pointer;
    margin-bottom: 10px;
  }
  .invalid {
    font-weight: bold;
    font-size: 14px;
    color: $error-red;
    padding-top: 1.2em;
  }
}

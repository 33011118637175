.profile {
  .edit {
    text-align: center;
    .icon {
      padding-bottom: 1em;
    }
    .title {
      font-weight: bold;
      font-size: 22px;
      padding-bottom: 2em;
    }
    h6 {
      font-weight: bold;
      padding-bottom: 1em;
    }
    .section {
      text-align: left;
      padding-bottom: 3em;
      .content {
        padding-bottom: 1.2em;
      }
    }
    .button-container {
      padding: 0.5em;
    }
  }
  .title {
    text-align: center;
    .icon {
      padding-bottom: 1em;
    }
    .name {
      font-weight: bold;
      font-size: 22px;
      padding-bottom: 0.5em;
    }
    .email {
      font-weight: normal;
      font-size: 16px;
      padding-bottom: 3em;
    }
    .edit {
      font-weight: normal;
      font-size: 22px;
      padding-bottom: 1.5em;
    }
  }
  .body {
    text-align: left;
    .store-info {
      font-weight: normal;
      font-size: 20px;
      padding-bottom: 1em;
    }
    .content-title {
      font-weight: normal;
      font-size: 16px;
      padding-bottom: 0.4em;
    }
    .content {
      font-weight: bold;
      font-size: 16px;
      padding-bottom: 1.5em;
    }
  }
  .logout {
    text-align: center;
    display: flex;
    justify-content: center;
    .logout-button {
      min-width: 150px;
      text-align: center;
      max-width: 30%;
      width: 15em;
    }
  }
}

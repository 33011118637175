.claim-info-modal {
  font-family: $font-secondary;
  .modal-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none !important;

    .close-row {
      width: 95%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .icon {
        &:hover {
          cursor: pointer;
        }
        float: right;
      }
    }

    padding: 0.5em 0;
    .modal-title {
      align-self: center;
      font-weight: bold;
      font-size: 20px;
      margin-top: 5px;
      .custom-button {
        margin-bottom: 0.5em;
      }
    }
  }
  .claim-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    text-transform: capitalize;
    .info-pair {
      margin: 10px 20px;
      .key {
        font-size: 1rem;
        font-weight: 400;
      }
      .value {
        font-size: 1.1rem;
        font-weight: 700;
        &.completed {
          color: $completed;
        }
        &.submitted {
          color: $submitted;
        }
      }
    }
  }
}

.item-card {
  margin: 11px 0px;
  font-family: $font-secondary;
  .delete-icon-container {
    padding: 2.5rem 2% 1.5rem 0;
    .delete-icon {
      cursor: pointer;
      height: 15px;
      width: 15px;
      svg {
        position: relative;
        bottom: 5px;
      }
    }
  }

  .card {
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 90%;
    max-width: 22rem;
    .container {
      width: 100%;
      padding: 0;
      .blue {
        background-color: $dark-blue;
        color: #ffffff;
        height: 6rem;
        width: 12%;
        float: left;
        padding: 0.5rem;
        border-radius: 5px 0 0 5px;
        text-align: center;
      }

      .edit-icon {
        cursor: pointer;
        float: right;
        padding: 4rem 0.8rem 0.5rem 0;
      }
      .body {
        text-transform: capitalize;
        height: 6rem;
        width: 75%;
        padding: 0.5rem 0 0.5rem 3%;
        float: left;
        .missing-msg {
          display: inline-block;
          font-size: 10px;
          color: $missing-red;
        }
        .card-title {
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          color: #272727;
          text-align: left;
          margin-bottom: 0;
        }

        .card-text {
          font-weight: normal;
          font-size: 12px;
          line-height: 17px;
          color: $solid-gray;
          text-align: left;
        }
      }
    }
  }
}

.claim-browser {
  .searcher {
    padding-top: 30px;
    border-top: 1px solid $faded-gray;
  }
  .claim-col {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .bad-search {
    padding: 10px 15px 30px 17px;
  }
  .none-found {
    padding: 10px 25px 0px 25px;
  }
}

.claim-type-tile {
  background-color: $dark-blue;
  color: white;
  border-radius: 15px;
  padding: 5px;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
  margin: 5px;
  cursor: pointer;
  &:hover {
    background-color: $dark-blue-hover;
  }
  .text {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .icon {
    padding-top: 35px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

.validated-input {
  display: flex;
  flex-direction: column;
  &.md {
    width: 100%;
  }
  &.lg {
    width: 100%;
  }
  &.sm {
    width: 5rem;
  }
  .list {
    margin-top: 6px;
    .validation {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      font-family: $font-secondary;
      font-size: 0.8rem;
      font-weight: 700;
      margin-top: 8px;
      height: 1.1rem;
      align-items: center;

      &.pass {
        color: $pass-green;
      }
      &.fail {
        color: $error-red;
      }
      &.none {
        display: none;
      }
    }
    &.none {
      display: none;
    }
  }
}

.recall-header {
  .header {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: $solid-gray;
    margin-bottom: 0.5em;
  }
  .sub-header {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    color: #000000;
    margin-bottom: 0.5em;
    div {
      line-height: 2;
    }
  }
  &.top-padding {
    padding-top: 12px;
  }
  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  hr {
    height: 1px;
    background-color: $faded-gray;
    margin-bottom: 0.5em;
  }
}

.comments {
  max-height: 50vh;
  overflow-y: scroll;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  .comments-list {
    padding: 0 1rem 1rem 1rem;
    border-radius: 0.5rem;
    .timestamp {
      width: 100%;
      float: left;
      text-align: center;
      color: #aaaaaa;
      padding: 0.8rem 0;
    }
    .comment {
      text-align: left;
      margin-bottom: 0;
      .user {
        float: left;
        width: 100%;
        padding: 0 0.3rem;
      }
      &.other {
        float: left;
        width: 94%;
        p {
          background: #cecece;
          margin: 0.2rem 0;
          padding: 0.4rem 0.9rem;
          min-width: 30%;
          max-width: 100%;
          display: inline-block;
        }
      }
      &.self {
        float: right;
        width: 94%;
        text-align: right;
        padding-right: 0.4rem;
        p {
          color: #ffffff;
          text-align: left;
          background: #486eb3;
          margin: 0.2rem 0;
          padding: 0.4rem 0.9rem;
          min-width: 30%;
          max-width: 100%;
          display: inline-block;
        }
      }
      &.status {
        background: #f3f3f3;
        border-radius: 8px;
        float: left;
        text-align: center;
        width: 100%;
        max-height: 6rem;
        padding: 1rem;
        p {
          padding: 0;
          margin: 0;
        }
      }
      p {
        border-radius: 8px;
      }
    }
  }
}
.submit-comment {
  width: 100%;
  form {
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.5em;
    input {
      padding-left: 0.5rem;
      padding-right: 1rem;
      border: 1px solid #000000;
      height: 2.5em;
      width: 24rem;
      border-radius: 8px;
    }
    margin-top: 2rem;
  }
}

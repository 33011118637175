.modal-warning {
  padding: 1em;
  .title {
    font-weight: bold;
    font-size: 18px;
    text-align: left;
    color: $missing-red;
    margin-bottom: 1.2em;
  }
  .highlight {
    display: inline;
    font-weight: bold;
    color: $missing-red;
  }
  .body {
    font-size: 14px;
    text-align: left;
    margin-bottom: 1em;
  }
  .button-container {
    padding: 0.5em;
    text-align: center;
    .padding {
      padding: 0 0.5em;
    }
  }
}

.modal-leave {
  padding: 1em;
  .title {
    font-weight: bold;
    font-size: 20px;
    text-align: left;
    margin-bottom: 1.5em;
  }
  .body {
    font-size: 14px;
    text-align: left;
    margin-bottom: 1.2em;
  }
  .row {
    justify-content: center;
    .half-btn {
      margin: 5px;
      min-width: 150px;
    }
  }
}

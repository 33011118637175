.selectable-item-tile {
  .wrapper {
    padding: 0;
    margin: 20px 0px;
    .card {
      box-shadow: 0px 1.61062px 16.1062px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      padding: 0;
      max-width: 500px;
      .card-body {
        font-family: Open Sans;
        font-style: normal;
        text-transform: capitalize;
        font-size: 0.9rem;
        width: 100%;
        padding: 10px 15px;
        img {
          width: 100%;
        }
        p {
          font-size: 0.75rem;
          color: gray;
          margin: 0;
        }
        .row {
          align-items: center;
          .check {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding-right: 15px;
          }
          .units {
            padding: 5px;
          }
        }
      }
    }
  }
}

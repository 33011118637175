.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: $dark-blue;
  .back {
    cursor: pointer;
  }
  .right {
    margin-left: auto;
  }
  .hover {
    cursor: pointer;
  }
  h1 {
    font-weight: 700;
    font-size: 1.6em;
  }

  a {
    font-family: $font-secondary;
    cursor: pointer;
  }
}

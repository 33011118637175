.claim-card {
  width: 100%;
  .card {
    width: 100%;
    max-width: 20rem;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    margin: 10px 0px;
    cursor: pointer;
    .claim-card-header {
      padding-bottom: 2px !important;
    }
    .card-body {
      font-family: Open Sans;
      font-style: normal;
      text-transform: capitalize;
      width: 96%;
      padding: 5% 0 5% 7%;
      .card-title {
        font-weight: bold;
        font-size: 95%;
        line-height: 25px;
        color: #272727;
        float: left;
        min-width: 8rem;
        text-align: left;
        margin-bottom: 0px;
      }
      .card-missing-text {
        color: $missing-red;
        display: inline;
      }
      .notif {
        position: absolute;
        right: 1.4rem;
        bottom: 1rem;
      }
      .card-text {
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: $solid-gray;
        display: inline;
        float: left;
        padding-right: 0.5em;
        width: 100%;
        &.submitted {
          color: $submitted;
          display: inline;
          float: right;
          padding-top: 1%;
          width: auto !important;
        }
        &.completed {
          color: $completed;
          display: inline;
          float: right;
          padding-top: 1%;
          width: auto !important;
        }
        &.completed_declined {
          color: $completed-declined;
          float: right;
          padding-top: 1%;
          max-width: 6.6rem;
          text-align: right;
          font-size: 13px;
          width: auto !important;
        }
        &.draft {
          color: $error-red;
          display: inline;
          float: right;
          padding-top: 1%;
          width: auto !important;
        }
        &.incomplete {
          color: $error-red;
          display: inline;
          float: right;
          padding-top: 1%;
          width: auto !important;
        }
        &.approved {
          color: $lcbo-green;
          display: inline;
          float: right;
          padding-top: 1%;
          width: auto !important;
        }
      }
    }
  }
}

// Override Default Variables
// https://getbootstrap.com/docs/5.0/customize/sass/#variable-defaults

// $primary: #6610f2;
// $secondary: #fd7e14;
$font-secondary: "DM Sans";
$dark-blue: #2a55a2;
$dark-blue-hover: #2c6ab2;
$light-green: #3f8c6d;
$lcbo-green: #04814d;
$faded-gray: #d7d7d7;
$solid-gray: #575757;
$error-red: #eb5757;
$missing-red: #b1243c;
$pass-green: #27ae60;

$completed: #27ae60;
$completed-declined: #eb964a;
$draft: $error-red;
$incomplete: $error-red;
$submitted: #2f80ed;

$desktop-max-width: 700px;

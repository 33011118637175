.expandable {
  margin-top: 40px;
  width: 100%;
  .topbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    cursor: pointer;
    .icon {
      width: 14px;
      transform: rotate(180deg);
      &.collapsed {
        width: 14px;
        transform: rotate(0deg);
      }
    }
  }
  .children {
    &.none {
      display: none;
    }
  }
}

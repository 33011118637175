.toast {
  position: fixed;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 100%);
  transition: all 250ms ease;
  color: #fff;
  opacity: 0;
  z-index: 200;
  padding: 0.75rem;
  font-size: 0.75rem;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  text-align: center;
  &.default {
    background: blue;
  }
  &.error {
    background: #c93022;
  }
  &.success {
    background: green;
  }
  &.visible {
    opacity: 1;
    transform: translate(-50%, -200%);
  }
}

.item-carousel-card {
  .card {
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
    border-radius: 15px;

    .card-body {
      font-family: Open Sans;
      font-style: normal;
      text-transform: capitalize;
      height: 16rem;
      width: 15rem;
      padding: 1rem 1.25rem;
      .card-title {
        font-weight: bold;
        font-size: 12.5px;
        line-height: 18px;
        color: #272727;
        text-align: center;
        margin-bottom: 0;
        height: 3em;
      }
      .product-image {
        display: flex;
        justify-content: center;

        img {
          height: 10.5rem;
          width: auto;
          display: block;
          margin: 0 auto;
          padding-bottom: 0.4rem;
        }
      }

      .card-text {
        float: left;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        color: $solid-gray;
        padding-left: 6em;
      }
      .index {
        float: right;
        font-weight: normal;
        font-size: 14px;
        color: #959595;
        margin-bottom: 1em;
      }
    }
  }
}

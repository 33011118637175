.text-input {
  .label {
    font-weight: normal;
    display: flex;
    flex-direction: row;
    font-size: 11px;
    line-height: 15px;

    color: $solid-gray;
    margin-bottom: 0.2rem;
    .asterisk {
      margin-left: 1px;
      color: $error-red;
    }
  }

  input {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    color: #000000;
    border: none;
    border-bottom: 1px solid #a5a5a5;
    width: 100%;
  }
  .md {
    width: 100%;
    flex-grow: 1;
  }
  .sm {
    width: 5rem;
  }
  textarea {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    width: 100%;
    border: 1px solid #a5a5a5;
    box-sizing: border-box;
    border-radius: 6px;
    margin-top: 0.3rem;
    padding: 0.3rem;
  }
  width: 100%;
}

.searchbar {
  border: 1px solid #959595;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0.2rem;
  color: #272727;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  input {
    border: none;
    padding: 0.2rem;
    display: flex;
    flex-grow: 1;
  }
  .search {
    margin: 0 1% 0 1%;
  }
  .clear-input {
    display: inline;
    margin-right: 2%;
    padding-bottom: 2px;
  }
}

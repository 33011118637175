.step-header {
  display: flex;
  flex-direction: column;
  .MuiStepper-root {
    padding-left: 0%;
    padding-right: 0%;
    .MuiStep-horizontal {
      padding-left: 2px;
      padding-right: 2px;
    }
  }
  .label {
    font-family: Open Sans;
  }
  .circle {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    border: 1px solid $dark-blue;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -7px;
    margin-bottom: -12px;
    &.active {
      color: white;
      background-color: $dark-blue;
    }
  }
}

.modal-content {
  max-width: 100% !important;
}
.radio-group-modal-content {
  padding: 24px;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-family: $font-secondary;
  .group {
    margin-top: 30px;
    .heading {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .clear {
        color: $error-red;
        cursor: pointer;
      }
    }
    .option {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      .radio {
        margin-right: 5px;
      }
    }
  }
  .button-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .cancel-all {
      justify-self: flex-start;
      .clear-btn {
        color: $solid-gray;
        background-color: white;
        border: none;
      }
    }
    .button-pair {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      .group-modal-btn {
        color: $dark-blue;
        background-color: white;
        margin-left: 25px;
        border: none;
        &:disabled {
          color: $faded-gray;
        }
      }
    }
  }
  h2 {
    font-weight: 400;
    font-size: 1.2rem !important;
  }
  h3 {
    font-weight: 700;
    font-size: 1rem;
  }
  p {
    font-weight: 400;
    font-size: 1rem;
  }
}

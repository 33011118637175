.home {
  .comments-modal-container {
    max-height: 10em;
  }
  .upper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0px 30px 0px;
    .cta {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      text-align: left;
      width: 100%;
    }
    .claim-types {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      text-align: center;
    }
  }
}

.dropdown {
  font-family: Open Sans;
  font-size: 12px;
  color: #272727;
  line-height: 15px;
  max-width: 100%;
  .label {
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;

    color: $solid-gray;
    margin-bottom: 0.2rem;
    display: flex;
    flex-direction: row;

    .asterisk {
      margin-left: 1px;
      color: $error-red;
    }
  }
  &-header {
    background: #ffffff;
    border: 1px solid #959595;
    border-radius: 6px;
    width: 100%;
    text-align: left;
    padding: 0.7rem 0.5rem;
    svg {
      float: right;
      margin: 0.2rem;
    }
  }
  &-list {
    margin-top: 0.2em;
    border: 1px solid #959595;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-item {
      background: #ffffff;
      border: none;
      border-bottom: 1px solid $faded-gray;
      border-radius: 5px;
      display: flex;
      flex-grow: 1;
      width: 100%;
      padding: 0.5rem;
      text-align: left;
    }
  }
  &-placeholder {
    font-size: 11px;
    color: rgba(5,5,5,0.25)
  }
}

.item-carousel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  .arrow {
    margin: 0px 20px;
    cursor: pointer;
    min-width: 19px;
    &.right {
      transform: scaleX(-1);
    }
  }
}

.comments-modal {
  .modal-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 7.8rem;

    .back-arrow {
      position: absolute;
      left: 1.2rem;
      top: 1.2rem;
      cursor: pointer;
    }

    padding: 0.5em 0;
    .modal-title {
      align-self: center;
      font-weight: bold;
      font-size: 20px;
      .product-info {
        font-weight: 400;
        font-size: 1.1rem;
      }
      .custom-button {
        margin-bottom: 0.5em;
      }
    }
  }
}

.images {
  text-align: center;
  padding: 1em;

  .uppy-Dashboard-close {
    display: none;
  }

  .open-modal-btn {
    display: inline-block;
    color: white;
    background: #2a55a2;
    border-radius: 10px;
    border: none;
    padding: 0.7em 1em;
    margin: 0.2em;
    display: cen;
  }
  .thumbnails {
    border: 1px dashed #a5a5a5;
    box-sizing: border-box;
    border-radius: 7px;
    min-height: 10%;
    width: 100%;
    margin-bottom: 1em;
    padding: 0.4em 0 0.4em 0.8em;
    text-align: left;

    .empty {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #515151;
      width: 100%;
      padding: 2rem 0;
      text-align: center;

      background: transparent;
      border: none;
    }

    .image-container {
      height: 6em;
      width: 6em;
      padding: 0.1rem;
      margin: 0.8rem;
      display: inline-block;
      .image {
        width: 100%;
        height: 100%;
        float: left;
        border-radius: 5px;
        margin-bottom: 0.2em;
      }
      a {
        display: flex;
      }
      .filename {
        color: #575757;
        font-size: 11px;
        line-height: 14px;
        text-align: center;
        height: 1px;
      }
      .delete {
        cursor: pointer;
        .delete-icon {
          position: relative;
          bottom: 6.5em;
          right: 1em;
        }
      }
    }
  }
  .uppy-Dashboard-close {
    position: absolute;
    left: -100em;
    top: 0;
    color: red;
  }
}
